@charset "utf-8";


//*basicInner*/
//コンテンツ幅
$pc_basicInner: 1200px;


///**********************************************************
//* 以下は色パターン情報 「基本サイト設計情報.xlsm」参考
//* 使いたいブロックを最後尾上書き
//***********************************************************/

$color_yellow1: #fde45c;
$color_yellow2: #feea7b;
$color_gray1: #555555;
$color_gray2: #f8f8f8;
$color_red1: #fc2c2c;
$color_red2: #f75544;
$color_border1: #000000;

$color_font: #333333;
$color_link1: #777777;
$color_link2: #239ce7;
$color_header_bg: #efefef;
$color_globalnav_border: #F39800;
$color_globalnav_li_bg: #F2E9DA;
$color_topicpath_font: #cccccc;
$color_topicpath_font_link_: #239ce7;
$color_h2_basic: #F39800;
$color_h3_basic: #F39800;
$color_header_footer: #efefef;
$color_header_footer_border: #cccccc;
$color_copyright_footer_bg: #666666;
$color_button: #00F299;
$color_caution_bg: #F22000;
$color_caution_font: #ffffff;