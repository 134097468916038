@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
/*
共通
-------------------------------------*/
.pageEntry {
  #Main {
    padding: 84px 0 152px;
    .contBox {
      .entryHead {
        padding-bottom: 26px;
        border-bottom: 3px solid $color_border1;
        margin-bottom: 50px;
        .info {
          align-items: center;
          margin-bottom: 15px;
          .date {
            font-weight: bold;
            color: $color_gray1;
            margin-right: 20px
          }
          .category {
            display: flex;
            li {
              width: auto !important;
              font-size: 12px;
              font-weight: bold;
              color: #fff;
              line-height: 20px;
              background-color: #000;
              padding: 0 12px;
              margin-right: 10px;
            }
          }
        }
        .title {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5;
          text-align: justify;
        }
      }
      .entryBox {
        img {
          max-width: 100%;
        }
      }
      .entryFoot {
        margin-top: 52px;
        padding-top: 30px;
        border-top: 3px solid $color_border1;
        .postNav {
          position: relative;
          display: flex;
          justify-content: center;
          li {
            a {
              font-weight: bold;
              text-decoration: none;
              background-repeat: no-repeat;
              background-size: 12px auto;
            }
            &.prev {
              position: absolute;
              top: 0;
              left: 0;
              a {
                padding-left: 28px;
                background-image: url(../img/contents/arrow_05.png);
                background-position: left top 6.5px;
              }
            }
            &.next {
              position: absolute;
              top: 0;
              right: 0;
              a {
                padding-right: 28px;
                background-image: url(../img/contents/arrow_06.png);
                background-position: right top 6.5px;
              }
            }
          }
        }
      }
    }
  }
}


/*
トップページ
-------------------------------------*/
#Page.pageIndex {
  #SNSBox {
    top: 37%;
  }
  #MainImg {
    height: auto;
    background-color: #fff;
    #MainImgInner {
      height: auto;
      flex-direction: row;
      justify-content: normal;
      align-items: normal;
      padding-top: 0;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 368px;
        background-color: $color_yellow1;
        left: 0;
        bottom: 0;
      }
      #Slick {
        width: calc(100% - 50px);
        background-color: #fff;
        .slick-list, .slick-track {
          overflow: hidden;
        }
        .item {
          width: 100%;
          a {
            width: 100%;
            img { width: 100%; }
            &.pc { display: block; }
            &.sp { display: none; }
            &.video {
              position: relative;
              overflow: hidden;
              width: 100%;
              padding-top: 56.08333333333333%;
              video {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
        .slick-dots {
          left: 50%;
          bottom: 15px;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
          li {
            width: 12px;
            height: 12px;
            margin: 0 15px 0 0;
            &:last-of-type { margin-right: 0; }
            button {
              width: 100%;
              height: 100%;
              background-color: #fff;
              padding: 0;
              border-radius: 50%;
              &:before { display: none; }
            }
            &.slick-active button {
              background-color: #fde45c;
            }
          }
        }
      }
    }
  }
  #Main {
    .contBox {
      position: relative;
      .contBoxTitle {
        h3 {
          font-size: 50px;
          font-weight: 900;
          line-height: 1;
          text-transform: capitalize;
        }
        span {
          display: block;
          font-size: 14px;
          font-weight: bold;
          margin-top: 6px;
        }
      }
    }
    .importantBox {
      background-color: $color_yellow1;
      padding: 62px 0 39px;
      dl {
        display: flex;
        background-color: #fff;
        border: 3px solid $color_red2;
        margin-bottom: 16px;
        dt {
          width: 169px;
          background-color: $color_red2;
          font-weight: bold;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        dd {
          width: calc(100% - 169px);
          padding: 19px 21px 20px 30px;
          .postList {
            li a { font-weight: bold; }
          }
        }
      }
    }
    #ContBox01 {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 197px;
        background-color: $color_yellow1;
        top: 0;
        left: 0;
        z-index: -1;
      }
      .contSubBox {
        margin-top: 23px;
        .scrollBox {
          margin-bottom: 41px;
        }
        .thumbPostList {
          li {
            width: 270px;
            margin-right: 40px;
            &:last-of-type { margin-right: 0; }
          }
        }
      }
    }
    #ContBox02 {
      margin-top: 73px;
      margin-bottom: 160px;
      .contBoxTitle {
        text-align: center;
        h3 { text-transform: inherit; }
        span { letter-spacing: 0.1em; }
      }
      .contSubBox {
        margin-top: 23px;
        .thumbPostList {
          margin-bottom: 50px;
          & > li {
            width: 372px;
            & + li { margin-left: 42px; }
            a {
              .content { padding-top: 17px; }
            }
          }
        }
      }
    }
    #ContBox03 {
      position: relative;
      .contSubBox {
        display: flex;
        justify-content: right;
        min-height: 900px;
        position: relative;
        padding-left: 33.3%;
        overflow: hidden;
        .contSubBoxTitle {
          position: absolute;
          top: 0;
          left: 0;
          clip: rect(auto, auto, auto, auto);
          width: 33.3%;
          height: 100%;
          padding-top: 62px;
          & > img {
            display: block;
            position: fixed;
            left: 0;
            top: 0;
            width: inherit;
            height: 100%;
            vertical-align: bottom;
            transform: translateZ(0);
            object-fit: cover;
            &.disp_sp { display: none; }
          }
          .en {
            position: relative;
            z-index: 1;
            width: 343px;
            font-size: 50px;
            font-weight: 900;
            color: #fff;
            letter-spacing: 0.02em;
            line-height: 1;
            margin: 0 auto;
            span {
              display: block;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.05em;
              margin-top: 13px;
            }
          }
        }
        .contSubBoxCont {
          width: 100%;
          background-color: $color_gray2;
          padding: 134px 60px 139px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .catTitle {
            margin-bottom: 51px;
            a {
              display: inline-block;
              background: url(../img/contents/arrow_02.png) no-repeat right center;
              background-size: 30px auto;
              font-weight: bold;
              line-height: 1;
              text-decoration: none;
              padding-right: 52px;
              position: relative;
              &:before {
                position: absolute;
                content: '';
                width: calc(100% - 52px);
                height: calc(100% - 6px);
                background-color: $color_yellow1;
                left: 0;
                bottom: 0;
              }
              p {
                font-size: 34px;
                letter-spacing: 0.02em;
                line-height: 1;
                text-align: left;
                position: relative;
              }
              span {
                display: block;
                font-size: 14px;
                letter-spacing: 0.14em;
                margin-top: 16px;
                position: relative;
              }
            }
          }
        }
      }
    }
    #ContBox04 {
      margin-top: 105px;
      margin-bottom: 160px;
      .contSubBox {
        .contSubBoxTitle {
          font-size: 104px;
          font-weight: bold;
          color: #f2f2f2;
          letter-spacing: 0.05em;
          line-height: 1;
          margin-bottom: 15px;
        }
        .youtubeList {
          display: flex;
          flex-wrap: wrap;
          .item {
            width: 580px;
            margin-bottom: 40px;
            &:nth-of-type(2n) { margin-left: 40px; }
            a {
              display: block;
              width: 100%;
              height: 100%;
              text-decoration: none;
            }
            .movie {
              position: relative;
              width: 100%;
              height: 326px;
              overflow: hidden;
              & > * {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
              }
            }
            p {
              font-size: 16px;
              font-weight: bold;
              line-height: 24px;
              text-align: left;
              background-color: $color_yellow1;
              padding: 14px 20px 15px;
            }
          }
        }
      }
      .contSubBox02 {
        margin-top: 30px;
        .contSubBoxTitle {
          text-align: right;
          letter-spacing: 0.02em;
          margin-bottom: 33px;
        }
      }
    }
    #ContBox05 {
      background-color: $color_gray2;
      padding: 81px 0 160px;
      .innerBasic {
        display: flex;
      }
      .boxHead {
        width: 270px;
        .contBoxTitle {
          margin-bottom: 21px;
          h3 { font-weight: 900; }
        }
        .moreBtn {
          width: 210px;
          margin: 0;
        }
      }
      .boxCont {
        width: calc(100% - 270px);
        padding-top: 1px;
        .js-newspost {
          display: none;
          &.show { display: block; }
        }
        .moreBtn { display: none; }
      }
    }
    #ContBox06 {
      .flexBox {
        .item {
          width: calc(100% / 4);
          height: 360px;
          a {
            display: block;
            width: 100%;
            height: 100%;
            text-decoration: none;
            text-align: center;
            padding-top: 60px;
            img { width: 200px; }
            p {
              font-size: 24px;
              font-weight: 900;
              text-align: center;
              line-height: 1;
              margin: 11px 0 3px;
            }
            span {
              display: block;
              font-size: 14px;
              font-weight: bold;
            }
          }
          &:nth-of-type(odd) a {
            background-color: $color_yellow1;
          }
          &:nth-of-type(even) a {
            background-color: $color_yellow2;
          }
        }
      }
    }
  }
}

@keyframes process {
  0% { width: 0; }
  100% { width: 100%; }
}

/*
製品情報
-------------------------------------*/
#PageProducts {
  #Header {
    #HeaderMenu {
      ul.mainMenu > li:first-of-type > a:before {
        display: block;
      }
    }
  }
  #MainImg {
    #MainImgInner {
      h2 { text-transform: capitalize; }
    }
  }
  #Main {
    padding: 85px 0 160px;
    .contBoxTitle {
      font-weight: bold;
      h3 {
        font-size: 37px;
        line-height: 1;
      }
      p {
        font-size: 14px;
        line-height: 18px;
        margin: 14px 0 0;
      }
    }
    .contBoxCont {
      background-color: $color_gray2;
      padding: 21px 60px 40px;
    }
    .contSubBox {
      .contSubBoxTitle {
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
        padding-bottom: 24px;
        margin-bottom: 40px;
        border-bottom: 3px solid #000;
      }
      .thumbPostList {
        & > li {
          width: 332px;
          background-color: transparent;
          margin-right: 42px;
          margin-bottom: 44px;
          &:nth-of-type(3n) { margin-right: 0; }
          a {
            .content {
              padding-top: 17px;
              .selldate {
                font-size: 14px;
                font-weight: bold;
                line-height: 1;
                color: $color_red1;
                margin: 6px 0 5px;
              }
              .tag { margin-top: 14px; }
              .desc {
                font-size: 14px;
                letter-spacing: 0.1em;
                text-align: justify;
                margin: 21px 0 13px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
              }
              .flexBox {
                justify-content: space-between;
                .item {
                  width: calc( (100% - 20px) / 2 );
                  background-color: #fff;
                  font-size: 14px;
                  padding: 17px 19px 13px;
                  dt {
                    font-weight: bold;
                    text-align: center;
                  }
                  dd {
                    padding-top: 1px;
                    ul {
                      li {
                        &:before {
                          content: '・';
                          padding-right: 0.5em;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#PageProducts.pageIndex {
  #Main {
    padding-top: 60px;
    .contBox {
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
        .contBoxTitle {
          display: block;
          width: 100%;
          text-decoration: none;
          background-image: url(../img/contents/icon_blank.png);
        }
      }
      .contBoxTitle {
        background: url(../img/contents/icon_open.png) no-repeat right center;
        background-size: 50px auto;
        padding: 25px 60px 25px 0;
        border-bottom: 3px solid $color_border1;
        &.active {
          background-image: url(../img/contents/icon_close.png);
          border-width: 0;
        }
        &:hover { opacity: 0.7; }
      }
    }
    .contSubBox {
      & + .contSubBox { margin-top: 26px; }
      .thumbPostList {
        & > li {
          &.lastest {
            width: 100%;
            a {
              // .thumb { padding-top: 400px; }
            }
          }
        }
      }
    }
    #ContBox01 {
      .contSubBox01 .thumbPostList {
        margin-right: -42px;
        & > li:nth-of-type(3n) { margin-right: 42px; }
      }
    }
  }
}

/*
製品情報 カテゴリ
-------------------------------------*/
#PageProducts.pageCategory {
  #Main {
    .contBox {
      .contBoxTitle {
        margin-bottom: 28px;
      }
      .contSubBox {
        & + .contSubBox { margin-top: 26px; }
        .contSubBoxTitle {
          padding-bottom: 21px;
          span {
            display: block;
            font-size: 14px;
            line-height: 18px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}

/*
製品情報 記事詳細
-------------------------------------*/
#PageProducts.pageEntry {
  #Main {
    padding: 90px 0 0;
    .contBox {
      position: relative;
      .notice {
        background-color: #fff6f5;
        border: 3px solid $color_red2;
        padding: 2px 0 4px;
        margin-bottom: 46px;
        p {
          font-size: 24px;
          font-weight: bold;
          color: $color_red2;
          text-align: center;
        }
      }
      .boxLink {
        margin-bottom: 75px;
        background-color: #ffffff;
        position: sticky;
        top: 140px;
        z-index: 10;
        ul {
          display: flex;
          border-bottom: 3px solid #000000;
          li {
            a {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #f8f8f8;
              font-size: 16px;
              line-height: 20px;
              text-decoration: none;
              padding: 8px 30px 9px;
              span {
                background: url(../img/contents/arrow_03.png) no-repeat left center;
                background-size: 14px auto;
                padding-left: 25px;
              }
            }
            + li { margin-left: 2px; }
          }
        }
        &.sticky {
          .innerBasic { width: 100%; }
        }
      }
      .contSubBox {
        h3 {
          font-size: 34px;
          font-weight: bold;
          line-height: 1.5;
          text-align: center;
        }
      }
      .entryHead {
        padding-bottom: 0;
        margin-bottom: 24px;
        border-bottom-width: 0;
        h3 {
          text-align: left;
          padding-bottom: 19px;
          border-bottom: 3px solid $color_border1;
        }
        h4 {
          font-size: 18px;
          font-weight: bold;
          background-color: $color_gray2;
          padding: 6px 18px 8px;
          margin: 16px 0 21px;
        }
        .info {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-weight: bold;
          margin-bottom: 0;
          .title {
            font-size: 24px;
            line-height: 36px;
            text-align: left;
            margin-right: 16px;
          }
          .selldate {
            font-size: 18px;
            color: $color_red1;
            margin-right: 18px;
          }
          .tag {
            display: flex;
            li {
              font-size: 12px;
              color: #fff;
              line-height: 1;
              background-color: #000;
              padding: 3.5px 10px 4.5px;
              margin-right: 10px;
            }
          }
        }
      }
      .entryPhoto {
        .slider {
          width: 100%;
          height: 673px;
          .slick-list {
            width: 100%;
            height: inherit;
          }
          .slick-track { height: inherit; }
          .slider-item {
            height: 100%;
            overflow: hidden;
            iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
        .sliderThumb {
          display: block;
          margin-bottom: -10px;
          .slick-track {
            transform: unset !important;
            width: 100% !important;
            display: flex;
            flex-wrap: wrap;
          }
          .slider-item {
            display: block;
            float: none;
            width: calc( (100% - 90px) / 10 ) !important;
            height: 81px;
            margin-bottom: 10px;
            &:nth-of-type(10) { margin-right: 0; }
            iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .entryContent {
        margin-top: 70px;
        .flexBox01 {
          .spotlight {
            width: 50%;
            padding-right: 60px;
            display: flex;
            align-items: center;
            & > * {
              font-size: 34px;
              font-weight: bold;
              line-height: 1.5;
            }
          }
          .content {
            width: 50%;
            padding-left: 30px;
            p {
              letter-spacing: 0.1em;
              & + p { margin-top: 21px; }
            }
            img {
              width: 100%;
              height: auto;
            }
            iframe {
              width: 100%;
              height: 100%;
              aspect-ratio: 16 / 9;
            }
          }
        }
        .flexBox02 {
          justify-content: right;
          margin-top: 32px;
          .item {
            width: 50%;
            padding-left: 30px;
            display: flex;
            justify-content: space-between;
            dl {
              width: calc( (100% - 20px) / 2 );
              background-color: $color_gray2;
              padding: 16px 35px 23px;
              dt {
                font-weight: bold;
                text-align: center;
              }
              dd {
                margin-top: 10px;
                ul {
                  margin-right: 20px;
                  li {
                    font-size: 14px;
                    &:before {
                      content: '・';
                      margin-right: 0.5em;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .featureBox,
      .techologBox {
        margin-top: 87px;
        .innerBasic {
          max-width: 1340px;
          width: 100%;
          min-width: 1200px;
        }
        h3 { margin-bottom: 29px; }
        .flexBox {
          flex-wrap: wrap;
          .item {
            width: calc( (100% - 40px) / 2 );
            margin-bottom: 72px;
            &:nth-of-type(even) { margin-left: 40px; }
            figure {
              height: 473px;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              margin-bottom: 30px;
            }
          }
        }
      }
      .reportBox {
        margin-top: 10px;
        .thumbPostList {
          margin-top: 32px;
          & > li {
            width: calc( (100% - 40px) / 2 );
            margin-right: 40px;
            &:nth-of-type(even) { margin-right: 0; }
            .content {
              padding-top: 24px;
            }
          }
        }
      }
      .instagramBox {
        margin-top: 77px;
        .subBox {
          background-color: $color_yellow1;
          padding: 50px 0;
          margin-top: 29px;
          img { display: none; }
        }
      }
      .specBox {
        margin-top: 87px;
        h3 { margin-bottom: 28px; }
        .subBox {
          & + .subBox { margin-top: 68px; }
        }
        .icon {
          height: 100%;
          img {
            width: auto;
            height: 75px;
          }
        }
        .title {
          font-size: 24px;
          font-weight: bold;
          line-height: 36px;
          margin: 12px 0 23px;
        }
        .table {
          position: relative;
          table {
            position: relative;
            border-spacing: 0;
            th, td {
              font-size: 14px;
              text-align: center;
              white-space: nowrap;
              border-right: 2px solid #fff;
              &:nth-of-type(1) {
                position: sticky;
                left: 0;
                padding: 12px 10px 10px;
                z-index: 1;
                &:before {
                  content: '';
                  width: 4px;
                  height: 100%;
                  background-color: #FFFFFF;
                  position: absolute;
                  top: 0;
                  right: -4px;
                  z-index: -1;
                }
                &:after {
                  position: absolute;
                  content: '';
                  height: 100%;
                  border-left: 2px solid #fff;
                  top: 0;
                  right: -1px;
                }
              }
              &.sticky {
                position: sticky;
                z-index: 1;
                left: 50px;
                z-index: 2;
                &:after {
                  position: absolute;
                  content: '';
                  height: 100%;
                  border-left: 2px solid #fff;
                  top: 0;
                  right: -1px;
                }
              }
              &:last-of-type { border-right-width: 0; }
            }
            th {
              background-color: $color_yellow1;
              padding: 12px 18px 10px 19px;
            }
            td {
              background-color: $color_gray2;
              border-bottom: 2px solid #fff;
              padding: 17px 18px 15px;
              &:nth-of-type(1) a {
                display: block;
                width: 24px;
                height: 18px;
                background: url(../img/contents/icon_07.png) no-repeat left top;
                background-size: 100% auto;
                transform: scale(-1, 1);
                margin: 0 auto;
              }
            }
            &.fixed {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              margin-left: 0;
              th, td {
                border-right: 2px solid #fff;
              }
            }
          }
        }
        .content {
          margin: 22px 0 56px;
          p { font-size: 14px; }
        }
        .gallery {
          flex-wrap: wrap;
          margin-bottom: -40px;
          figure {
            width: calc( (100% - 84px) / 3 );
            margin-right: 42px;
            margin-bottom: 40px;
            &:nth-of-type(3n) { margin-right: 0; }
            img { width: 100%; }
          }
        }
      }
      .orderBox {
        background-color: #f8f8f8;
        margin-top: 100px;
        padding: 34px 0 80px;
        h3 {
          font-size: 34px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
        }
        ul {
          display: flex;
          li {
            width: calc( (100% - 40px) / 2);
            min-height: 120px;
            margin-right: 40px;
            &:nth-of-type(even) { margin-right: 0; }
            a {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 24px;
              font-weight: bold;
              text-decoration: none;
              background: #fff url(../img/contents/arrow_04.png) no-repeat right 58px center;
              background-size: 16px auto;
              padding: 33px 80px;
              border: 3px solid #000000;
            }
          }
        }
      }
      .relatedBox {
        background-color: $color_gray2;
        padding: 50px 0 75px;
        margin-top: 120px;
        .thumbPostList {
          margin-top: 30px;
          margin-bottom: -40px;
          li {
            width: calc( (100% - 120px) / 4 );
            margin-right: 40px;
            margin-bottom: 40px;
            &:nth-of-type(4n) { margin-right: 0; }
            a {
              .content {
                padding-top: 10px;
                .title { text-align: left; }
              }
            }
          }
        }
      }
    }
  }
}

/*
プロスタッフ
-------------------------------------*/
#PageProStaff.pageIndex {
  #Header {
    #HeaderMenu {
      ul.mainMenu > li:nth-of-type(2) > a:before {
        display: block;
      }
    }
  }
  #Main {
    padding: 79px 0 160px;
    .contBox {
      margin-bottom: 70px;
      &:last-of-type { margin-bottom: 0; }
      h3 {
        text-align: center;
        padding-bottom: 22px;
        border-bottom: 3px solid $color_border1;
      }
      .contSubBox {
        margin-top: 50px;
        .photoBox {
          width: 480px;
          margin-right: 50px;
          .sliderThumb {
            .slider-item {
              width: calc( (100% - 40px) / 5 );
              height: 63px;
              img { height: 100%; }
            }
          }
        }
        .contentBox {
          width: calc(100% - 530px);
          .desc {
            background-color: $color_gray2;
            padding: 21px 36px 21px 28px;
          }
          .area {
            margin-top: 35px;
            h4 {
              font-size: 18px;
              font-weight: bold;
              line-height: 27px;
              margin-bottom: 5px;
            }
          }
          .sns {
            align-items: center;
            margin-top: 22px;
            li {
              margin-right: 20px;
              a {
                display: block;
                width: 30px;
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 100% auto;
                text-indent: -9999px;
                &.instagram {
                  height: 30px;
                  background-image: url(../img/contents/icon_insta.png);
                }
                &.youtube {
                  height: 21px;
                  background-image: url(../img/contents/icon_youtube.png);
                }
                &.facebook {
                  height: 30px;
                  background-image: url(../img/contents/icon_fb.png);
                }
                &.twitter {
                  height: 25px;
                  background-image: url(../img/contents/icon_tw.png);
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
ニュース
-------------------------------------*/
#PageNews {
  #Header {
    #HeaderMenu {
      ul.mainMenu > li:nth-of-type(3) > a:before {
        display: block;
      }
    }
  }
  #Main {
    padding: 82px 0 160px;
    .linkArea {
      margin-bottom: 60px;
      .newsTabList {
        justify-content: center;
      }
      .newsCatList {
        display: flex;
        flex-wrap: wrap;
        background-color: $color_gray2;
        padding: 10px 20px;
        li {
          margin-right: 38px;
          &.current a { background: linear-gradient(transparent 80%, $color_yellow1 80%); }
          a {
            font-weight: bold;
            text-decoration: none;
          }
        }
      }
      .newsCatList01 { margin-top: 16px; }
      .newsCatList02 {
        padding: 9px 20px;
        margin-top: 10px;
      }
    }
    .thumbPostList {
      margin-bottom: -50px;
      & > li {
        width: calc( (100% - 120px) / 4 );
        margin-right: 40px;
        margin-bottom: 50px;
        &:nth-of-type(4n) { margin-right: 0; }
        a {
          .content {
            .category { margin-top: 10px; }
          }
        }
      }
    }
    .moreBtn {
      margin-top: 75px;
      background-image: url(../img/contents/open.png);
      background-size: 16px auto;
      background-position: right 17px center;
    }
  }
}

/*
販売店情報
-------------------------------------*/
#PageStore.pageIndex {
  #MainImg {
    #MainImgInner {
      padding-top: 0;
      justify-content: center;
    }
  }
  #Main {
    padding: 80px 0 160px;
    .contBox {
      .contBoxTitle {
        padding-bottom: 23px;
        border-bottom: 3px solid $color_border1;
      }
      .pageNav {
        margin-top: 20px;
        ul {
          li {
            margin-right: 60px;
            &:last-of-type { margin-right: 0; }
            a {
              display: block;
              text-decoration: none;
              background: url(../img/contents/arrow_03.png) no-repeat left top 11px;
              background-size: 14px auto;
              padding-left: 22px;
            }
          }
        }
      }
      .contSubBox {
        margin-top: 100px;
        h4 {
          line-height: 36px;
          text-align: center;
          background-color: $color_gray2;
          padding: 9px 0 10px;
          margin-bottom: 30px;
        }
        .subBox {
          margin-bottom: 59px;
          &:last-of-type { margin-bottom: 0; }
          .click {
            font-size: 24px;
            font-weight: bold;
            line-height: 36px;
            padding: 1px 40px 24px 0;
            background: url(../img/contents/icon_open.png) no-repeat right top;
            background-size: 40px auto;
            border-bottom: 3px solid $color_border1;
            &.active {
              background-image: url(../img/contents/icon_close.png);
            }
          }
          .showBox {
            dl {
              margin-top: 43px;
              dt {
                display: flex;
                p {
                  font-size: 18px;
                  font-weight: bold;
                  line-height: 33px;
                }
                ul {
                  margin-top: 7px;
                  margin-left: 20px;
                  li {
                    width: 90px;
                    height: 24px;
                    background-repeat: no-repeat;
                    background-position: left top;
                    background-size: 100% auto;
                    margin-right: 10px;
                    &.zenith { background-image: url(../img/contents/logo_zenith.jpg); }
                    &.okuma { background-image: url(../img/contents/logo_okuma.jpg); }
                  }
                }
              }
              dd {
                margin-top: 8px;
                ul {
                  margin-top: 20px;
                  li {
                    margin-right: 10px;
                    a {
                      display: block;
                      min-width: 70px;
                      font-size: 14px;
                      text-decoration: none;
                      background: url(../img/contents/arrow_04.png) no-repeat right 7px center;
                      background-size: 8px auto;
                      padding: 1px 26px 1px 10px;
                      border: 1px solid #000;
                      &.tel { pointer-events: none; }
                    }
                  }
                }
              }
            }
          }
        }
      }
      #ContSubBox01 {
        margin-top: 73px;
      }
    }
  }
}

/*
サポート
-------------------------------------*/
#PageSupport {
  #Header {
    #HeaderMenu {
      ul.mainMenu > li:nth-of-type(5) > a:before {
        display: block;
      }
    }
  }
  #Main {
    .contBox {
      .contBoxTitle { text-align: center; }
    }
  }
}

#PageSupport.pageIndex {
  #Main {
    padding: 82px 0 160px;
    .linkBox {
      .newsTabList {
        justify-content: center;
      }
      .flexBox {
        justify-content: space-between;
        margin-top: 60px;
        .item {
          width: 580px;
          height: 330px;
          a {
            display: block;
            width: 100%;
            height: 100%;
            background-color: $color_yellow1;
            text-decoration: none;
            text-align: center;
            padding-top: 40px;
            img { width: 200px; }
            p {
              font-size: 24px;
              font-weight: 900;
              text-align: center;
              line-height: 1;
              margin: 11px 0 3px;
            }
            span {
              display: block;
              font-size: 14px;
              font-weight: bold;
            }
          }
        }
      }
    }
    .contBox {
      margin-top: 89px;
      .contBoxTitle {
        text-align: center;
        margin-bottom: 32px;
      }
    }
    #ContBox01 {
      .contSubBox {
        .thumbPostList {
          flex-wrap: wrap;
          margin-bottom: -44px;
          li {
            width: calc( (100% - 120px ) / 4 );
            margin-right: 40px;
            margin-bottom: 44px;
            &:nth-of-type(4n) { margin-right: 0; }
          }
        }
        .moreBtn {
          width: 260px;
          margin-top: 53px;
        }
      }
    }
    #ContBox02 {
      .contSubBox {
        .post {
          border-top: 3px solid $color_border1;
          .subBox {
            .click {
              background: url(../img/contents/open.png) no-repeat right 20px center;
              background-size: 16px auto;
              padding: 28px 56px 28px 0;
              border-bottom: 1px solid $color_border1;
              &.active {
                background-image: url(../img/contents/close.png);
                border-bottom-width: 0;
              }
              p {
                font-weight: bold;
                line-height: 24px;
              }
            }
            .showBox {
              background-color: $color_gray2;
              padding: 15px 24px;
            }
          }
        }
        .button {
          justify-content: center;
          margin-top: 50px;
          li {
            & + li { margin-left: 40px; }
            a {
              display: flex;
              width: 270px;
              height: 70px;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              line-height: 24px;
              text-align: center;
              text-decoration: none;
              border: 3px solid $color_border1;
            }
          }
        }
      }
    }
    #ContBox03 {
      .contSubBox {
        .telBox {
          background-color: $color_yellow1;
          flex-direction: column;
          font-weight: bold;
          align-items: center;
          padding: 58px 0 52px;
          .tel {
            line-height: 1;
            margin-bottom: 22px;
            p {
              font-size: 28px;
              line-height: 1;
              margin-bottom: 8px;
              text-align: center;
            }
            h4 { font-size: 48px; }
          }
        }
        .formBox {
          margin-top: 75px;
          p {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.5;
            text-align: center;
          }
          .form {
            padding-top: 45px;
            margin-top: 26px;
            table {
              tr {
                &:nth-of-type(4) th {
                  vertical-align: baseline;
                }
                &:last-of-type th {
                  line-height: 62px;
                  vertical-align: top;
                }
              }
              td {
                span {
                  position: relative;
                  top: -5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
サポート 確認画面
-------------------------------------*/
#PageSupport.pageConfirm {
  #Main {
    padding: 80px 0 160px;
    .contBox {
      .contSubBox {
        margin-top: 52px;
        .textBox {
          p {
            font-size: 18px;
            font-weight: bold;
            line-height: 32px;
            text-align: center;
          }
        }
        .errorBox {
          margin-top: 20px;
          p {
            text-align: center;
            &:last-of-type { margin-top: 40px; }
          }
        }
        .formBox {
          margin-top: 63px;
          .form {
            padding-top: 45px;
            table {
              th, td { padding: 29px 0; }
            }
            .button {
              margin-top: 65px;
              padding-top: 49px;
              position: relative;
              .return {
                position: absolute;
                left: 0;
                bottom: 17px;
              }
            }
          }
        }
      }
    }
  }
}

/*
サポート 完了画面
-------------------------------------*/
#PageSupport.pageThanks {
  #Main {
    padding: 80px 0 140px;
    .contBox {
      .contSubBox {
        margin-top: 52px;
        p {
          font-size: 18px;
          font-weight: bold;
          line-height: 32px;
          text-align: center;
        }
        .moreBtn {
          margin-top: 66px;
          width: 275px;
        }
      }
    }
  }
}

/*
修理について
-------------------------------------*/
#PageRepair.pageIndex {
  #Header {
    #HeaderMenu {
      ul.mainMenu > li:nth-of-type(5) > a:before {
        display: block;
      }
    }
  }
  #Main {
    padding: 82px 0 170px;
    .linkBox {
      .newsTabList {
        justify-content: center;
      }
    }
    .contBox {
      margin-top: 49px;
      .contBoxTitle {
        text-align: center;
        margin-bottom: 23px;
      }
      .contSubBox {
        margin-bottom: 64px;
        &:last-of-type { margin-bottom: 0; }
        .contSubBoxTitle {
          padding-bottom: 24px;
          margin-bottom: 50px;
          border-bottom: 3px solid $color_border1;
          small { font-size: 16px; }
        }
        p {
          strong { font-weight: bold; }
        }
        span {
          &.note {
            display: block;
            position: relative;
            padding-left: 1em;
            &:before {
              position: absolute;
              content: '※';
              top: 0;
              left: 0;
            }
          }
          &.num {
            position: absolute;
            left: 20px;
          }
          &.red {
            font-size: 14px;
            color: $color_red2;
          }
        }
        table {
          width: 100%;
          tr {
            &:last-of-type {
              th, td { border-bottom-width: 0; }
            }
          }
          th, td {
            font-size: 14px;
            padding: 16px 20px;
            border-bottom: 2px solid #fff;
          }
          th {
            width: 240px;
            background-color: $color_yellow1;
            line-height: 24px;
            position: relative;
            &.light { background-color: #ffef95; }
            &.num {
              padding-left: 43px;
            }
          }
          td {
            background-color: $color_gray2;
            letter-spacing: 0.075em;
            border-left: 2px solid #fff;
            p {
              font-size: 14px;
              letter-spacing: 0.1em;
              & + p { margin-top: 16px; }
            }
            ul {
              & > li {
                position: relative;
                padding-left: 1em;
                &:before {
                  position: absolute;
                  content: '・';
                  top: 0;
                  left: 0;
                }
                & + li { margin-top: 14px; }
              }
            }
            ol {
              padding: 6px 0 2px;
              li {
                ul {
                  margin: 1px 0 10px;
                  li + li { margin-top: 0; }
                }
              }
            }
          }
        }
        ol {
          li {
            padding-left: 30px;
            ul {
              li + li { margin-top: 0; }
            }
          }
        }
        ul {
          & > li {
            position: relative;
            padding-left: 1em;
            text-align: justify;
            &:before {
              position: absolute;
              content: '・';
              top: 0;
              left: 0;
            }
          }
        }
        .subBox {
          margin-top: 46px;
          .subBoxTitle {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 14px;
          }
        }
      }
      .contSubBox01 {
        .subBox03 {
          margin-top: 75px;
          span.note { margin-top: -2px; }
          ol {
            li + li { margin-top: 14px; }
          }
        }
      }
      .contSubBox02 {
        .contSubBoxTitle { margin-bottom: 41px; }
        ul {
          & > li {
            letter-spacing: 0.075em;
            & + li { margin-top: 22px; }
            ol {
              padding: 14px 0 3px;
              & > li {
                ul { margin: 2px 0 14px; }
              }
            }
          }
        }
      }
      .contSubBox03 {
        table {
          th {
            font-size: 16px;
            text-align: center;
            padding: 17px 20px 18px;
            & + th { border-left: 2px solid #fff; }
          }
          td {
            vertical-align: middle;
            &:first-of-type { border-left-width: 0; }
            &:last-of-type {
              p {
                text-align: right;
                small {
                  display: block;
                  font-size: 12px;
                  margin-top: -4px;
                }
              }
            }
          }
        }
        & > p {
          &.right {
            text-align: right;
            margin: 9px 0 26px;
            small { font-size: 14px; }
          }
        }
      }
      .contSubBox04 {
        .contSubBoxTitle {
          color: $color_red2;
          text-align: center;
          border-bottom-width: 0;
          padding-bottom: 0 !important;
          margin-bottom: 0 !important;
        }
        .subBox {
          margin-top: 24px;
          border: 3px solid $color_red2;
          background-color: #fff6f5;
          padding: 48px 46px 50px;
          ul {
            li {
              color: $color_red2;
              padding-left: 16px;
              &:before {
                content: '';
                width: 6px;
                height: 6px;
                background-color: $color_red2;
                border-radius: 50%;
                top: 14px;
              }
              & + li { margin-top: 14px; }
              .note {
                font-size: 14px;
                margin-top: -4px;
              }
            }
          }
        }
      }
    }
  }
}

/*
パーツリスト
-------------------------------------*/
#PagePartsList.pageIndex {
  #Header {
    #HeaderMenu {
      ul.mainMenu > li:nth-of-type(5) > a:before {
        display: block;
      }
    }
  }
  #Main {
    padding: 82px 0 160px;
    .linkBox {
      margin-bottom: 49px;
      .newsTabList {
        justify-content: center;
      }
    }
    .contBox {
      .contBoxTitle {
        text-align: center;
        margin-bottom: 31px;
      }
      .contSubBox {
        margin-bottom: 40px;
        &:last-of-type { margin-bottom: 0; }
        .contSubBoxTitle {
          background: url(../img/contents/icon_open.png) no-repeat right top;
          background-size: 50px auto;
          padding: 7px 75px 27px 0;
          border-bottom: 3px solid $color_border1;
          &.active { background-image: url(../img/contents/icon_close.png); }
        }
        & > .showBox { padding-bottom: 40px; }
        .subBox {
          margin-top: 16px;
          .subBoxTitle {
            font-size: 18px;
            font-weight: bold;
            background: $color_gray2 url(../img/contents/open.png) no-repeat right 20px center;
            background-size: 16px auto;
            padding: 6px 56px 8px 20px;
            &.active { background-image: url(../img/contents/close.png); }
          }
          .showBox {
            padding: 33px 0 22px;
            .post {
              & + .post { margin-top: 50px; }
              h6 {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.5;
                margin-bottom: 14px;
              }
              .scroll { margin-bottom: 20px; }
              table {
                width: 100%;
                th, td {
                  width: auto;
                  min-width: 180px;
                  font-size: 14px;
                  line-height: 1.5;
                  text-align: center;
                  white-space: nowrap;
                  padding: 0 42px;
                  border-left: 2px solid #fff;
                  &:first-of-type  {
                    width: 472px;
                    border-left-width: 0;
                  }
                }
                th {
                  background-color: $color_yellow1;
                  padding: 14px 5px 15px;
                }
                td {
                  vertical-align: middle;
                  background-color: $color_gray2;
                  border-bottom: 2px solid #fff;
                  padding: 19px 5px 20px;
                  a {
                    text-decoration: none;
                    &[target="_blank"] {
                      padding-right: 26px;
                      background: url(../img/contents/icon_download.png) no-repeat right 1px center;
                      background-size: 16px auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
販売店様専用
-------------------------------------*/
#PageCustomer.pageIndex {
  #MainImg {
    #MainImgInner {
      padding-top: 0;
      justify-content: center;
    }
  }
  #Main {
    padding: 90px 0 160px;
    .notice {
      margin-bottom: 89px;
      dl {
        display: flex;
        border: 2px solid $color_border1;
        dt {
          width: 174px;
          font-weight: bold;
          background-color: $color_yellow1;
          padding: 12px 18px;
        }
        dd {
          width: calc(100% - 174px);
          padding: 12px 20px;
          a { text-decoration: none; }
        }
      }
    }
    .contBox {
      margin-bottom: 90px;
      &:last-of-type { margin-bottom: 0; }
      .contBoxTitle {
        padding-bottom: 23px;
        border-bottom: 3px solid $color_border1;
      }
      .contSubBox {
        .subBox {
          margin-top: 60px;
          .click {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.5;
            padding: 1px 40px 24px 0;
            background: url(../img/contents/icon_open.png) no-repeat right top;
            background-size: 40px auto;
            border-bottom: 3px solid $color_border1;
            &.active {
              background-image: url(../img/contents/icon_close.png);
            }
          }
          .showBox {
            padding-top: 32px;
            margin-bottom: -9px;
            .postList {
              li {
                & + li { margin-top: 14px; }
                a {
                  display: inline;
                  background-image: none;
                  text-decoration: underline;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*
リンク
-------------------------------------*/
#PageLink.pageIndex {
  #Main {
    padding: 80px 0 150px;
    .contBox {
      margin-bottom: 83px;
      &:last-of-type { margin-bottom: 0; }
      .innerBasic { width: 1000px; }
      .contBoxTitle {
        text-align: center;
        padding-bottom: 22px;
        border-bottom: 3px solid $color_border1;
      }
      .contBoxCont {
        margin-top: 41px;
        dl {
          & + dl { margin-top: 43px; }
          dt {
            margin-bottom: 22px;
            h4 {
              font-size: 24px;
              font-weight: bold;
              line-height: 1.5;
            }
            p {
              margin-top: -1px;
              a[target="_blank"] {
                padding-right: 23px;
                background: url(../img/contents/blank_01.png) no-repeat right 1px center;
                background-size: 15px auto;
              }
            }
          }
        }
      }
    }
  }
}

/*
企業情報
-------------------------------------*/
#PageCompany.pageIndex {
  #Main {
    padding-bottom: 160px;
    .leadImage {
      display: none;
      position: relative;
      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 120px;
        background-color: $color_yellow1;
        top: 0;
        left: 0;
      }
      figure {
        position: relative;
        max-width: 1340px;
        height: 400px;
        margin: 0 auto;
        background: url(../img/contents/company/img_01.jpg) no-repeat center top;
        background-size: 100% auto;
      }
    }
    .pageNav {
      margin: 52px 0 69px;
      ul {
        justify-content: center;
        padding-bottom: 12px;
        border-bottom: 3px solid $color_border1;
        li {
          & + li { margin-left: 58px; }
          a {
            font-weight: bold;
            text-decoration: none;
            &.current { background-color: $color_yellow1; }
          }
        }
      }
    }
    .contBox {
      display: none;
      margin-bottom: 81px;
      &:last-of-type { margin-bottom: 0; }
      .contBoxTitle {
        padding-bottom: 21px;
        border-bottom: 3px solid $color_border1;
      }
      .contSubBox {
        margin-top: 40px;
        dl {
          & + dl { margin-top: 32px; }
          dt {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 14px;
          }
          dd {
            p {
              font-size: 18px;
            }
          }
        }
        ol {
          margin-top: 22px;
          li { padding-left: 34px; }
        }
        table {
          width: 100%;
          th, td {
            line-height: 24px;
            padding: 19px 20px 17px;
            border-bottom: 2px solid #fff;
          }
          th {
            width: 15%;
            background-color: $color_yellow1;
          }
          td {
            width: 85%;
            background-color: $color_gray2;
            text-align: justify;
            border-left: 2px solid #fff;
          }
        }
      }
    }
    #ContBox03,
    #ContBox04 {
      .contSubBox {
        margin-top: 50px;
      }
    }
    #ContBox03 { display: block; }
  }
}

/*
社員あいさつ
-------------------------------------*/
#PageMessage.pageIndex {
  #Main {
    padding: 92px 0 152px;
    .pageNav {
      margin-bottom: 50px;
      ul {
        justify-content: center;
        padding-bottom: 12px;
        border-bottom: 3px solid $color_border1;
        li {
          & + li { margin-left: 58px; }
          a {
            font-weight: bold;
            text-decoration: none;
            &.current { background-color: $color_yellow1; }
          }
        }
      }
    }
    .contBox {
      .contBoxTitle {
        text-align: center;
        margin: 36px 0 30px;
      }
      .contSubBox {
        margin-bottom: 84px;
        &:last-of-type { margin-bottom: 0; }
        h4, h5 {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5;
        }
        h4 {
          padding-bottom: 25px;
          border-bottom: 3px solid $color_border1;
          margin-bottom: 40px;
        }
        h5 { margin-bottom: 15px; }
        .text {
          width: 1000px;
          margin: 0 auto;
          p {
            letter-spacing: 0.1em;
            & + p { margin-top: 22px; }
            &.right {
              text-align: right;
              margin-top: 31px;
              span {
                display: block;
                &:last-of-type { margin-top: -1px; }
              }
              small { font-size: 14px; }
              strong {
                font-size: 18px;
                font-weight: bold;
              }
            }
          }
        }
        div.flexBox {
          margin-bottom: 53px;
          .item01 {
            width: 575px;
            margin-right: 50px;
            padding-top: 10px;
            img { width: 100%; }
          }
          .item02 {
            width: calc(100% - 625px);
            p { letter-spacing: 0.085em; }
          }
        }
        .career {
          margin-bottom: 44px;
          dl {
            display: flex;
            & + dl { margin-top: 12px; }
            dt { width: 152px; }
          }
        }
      }
    }
  }
}

/*
プライバシーポリシー
-------------------------------------*/
#PagePolicy.pageIndex {
  #Main {
    padding: 80px 0 160px;
    .contBox {
      .innerBasic { width: 1000px; }
      p {
        letter-spacing: 0.075em;
        & + p { margin-top: 22px; }
        strong { font-weight: bold; }
      }
      .contSubBox {
        margin-top: 64px;
        h4 {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.5;
          padding-bottom: 24px;
          border-bottom: 3px solid $color_border1;
          margin-bottom: 41px;
        }
        ul {
          li {
            position: relative;
            padding-left: 0.6em;
            &:before {
              position: absolute;
              content: '・';
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}


@media print,
screen and (min-width: 768px) {
  #PageProducts.pageEntry #Main {
    .contBox {
      .specBox {
        .icon {
          overflow-x: auto !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
}


@media print,
screen and (max-width: 767px) {
  /*
  共通
  -------------------------------------*/
  .pageEntry {
    #Main {
      padding: 50px 0 90px;
      .contBox {
        .entryHead {
          padding-bottom: 15px;
          margin-bottom: 26px;
          .info {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 12px;
            .date { margin: 0; }
          }
          .title { font-size: 22px; }
        }
        .entryFoot {
          margin-top: 26px;
          padding-top: 17px;
          .postNav {
            li {
              a {
                background-size: 11px auto;
              }
              &.prev {
                a {
                  padding-left: 21px;
                  background-position: left top 7.5px;
                }
              }
              &.next {
                a {
                  padding-right: 22px;
                  background-position: right top 7.5px;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg {
      #MainImgInner {
        padding: 0;
        &:before {
          height: 299px;
        }
        #Slick {
          width: calc(100% - 20px);
          .item {
            a {
              background-size: cover;
              &.pc { display: none; }
              &.sp { display: block; }
            }
          }
        }
      }
    }
    #Main {
      .contBox {
        .contBoxTitle {
          h3 {
            font-size: 37px;
          }
          span { margin-top: 3px; }
        }
      }

      .importantBox {
        padding: 20px 0 21px;
        dl {
          flex-wrap: wrap;
          margin: 24px 0 11px;
          dt {
            width: 100%;
            padding-top: 0;
            padding-bottom: 1px;
          }
          dd {
            width: 100%;
            padding: 5px 10px 7px 15px;
            .postList {
              li a {
                p {
                  letter-spacing: 0.05em;
                  margin-top: -1px;
                }
              }
            }
          }
        }
      }
      #ContBox01 {
        &:before { height: 160px; }
        .contSubBox {
          margin-top: 18px;
          .scrollBox {
            width: calc(100% + 20px);
            overflow-x: scroll;
            padding-bottom: 15px;
            margin-bottom: 20px;
          }
          .thumbPostList {
            width: 1120px;
            flex-wrap: nowrap;
            justify-content: left;
            li {
              width: 250px;
              margin-right: 20px;
            }
          }
        }
      }
      #ContBox02 {
        margin-top: 77px;
        margin-bottom: 80px;
        .contBoxTitle {
          text-align: left;
        }
        .contSubBox {
          margin-top: 22px;
          .thumbPostList {
            margin-bottom: 45px;
            & > li {
              width: 100%;
              & + li { margin: 40px 0 0; }
              a {
                .content { padding-top: 16px; }
              }
            }
          }
        }
      }
      #ContBox03 {
        .contSubBox {
          min-height: auto;
          flex-wrap: wrap;
          padding-left: 0;
          .contSubBoxTitle {
            position: relative;
            width: 100%;
            height: 180px;
            padding: 27px 17px 0;
            & > img {
              position: absolute;
              &.disp_pc { display: none; }
              &.disp_sp { display: block; }
            }
            .en {
              width: 100%;
              font-size: 37px;
              span {
                margin-top: 10px;
                font-size: 12px;
                letter-spacing: 0.22em;
              }
            }
          }
          .contSubBoxCont {
            width: 100%;
            padding: 20px 20px 28px;
            .catTitle {
              margin-bottom: 23px;
              a {
                background-size: 22px auto;
                padding-right: 41px;
                &:before {
                  width: calc(100% - 41px);
                  height: calc(100% - 13px);
                  top: 10.5px;
                }
                p {
                  font-size: 25px;
                  letter-spacing: 0.05em;
                  line-height: 1.5;
                }
                span {
                  font-size: 12px;
                  letter-spacing: 0.125em;
                  line-height: 18px;
                  margin-top: 3px;
                }
              }
            }
          }
        }
      }
      #ContBox04 {
        margin-top: 53px;
        margin-bottom: 80px;
        .contSubBox {
          .contSubBoxTitle {
            font-size: 55px;
          }
          .youtubeList {
            .item {
              margin-bottom: 40px;
              &:nth-of-type(2n) { margin-left: 0; }
              .movie {
                height: 0;
                padding-top: 56.11940298507463%;
              }
              p {
                padding: 10px 16px 9px;
              }
            }
          }
        }
        .contSubBox01 {
          .contSubBoxTitle {
            margin-bottom: 11px;
          }
        }
        .contSubBox02 {
          margin-top: 0;
          .contSubBoxTitle {
            margin-bottom: 25px;
          }
        }
      }
      #ContBox05 {
        padding: 53px 0 80px;
        .innerBasic {
          flex-wrap: wrap;
        }
        .boxHead {
          width: 100%;
          .contBoxTitle {
            text-align: center;
          }
        }
        .boxCont {
          width: 100%;
          overflow: hidden;
          .newsPostList { margin-bottom: 45px; }
        }
      }
      #ContBox06 {
        .flexBox {
          .item {
            width: 50%;
            height: auto;
            a {
              padding: 22px 0 6px;
              img { width: 102px; }
              p { margin: 5px 0 4px; }
              span { font-size: 12px; }
            }
            &:nth-of-type(3) a {
              background-color: #feea7b;
            }
            &:nth-of-type(4) a {
              background-color: #fde45c;
            }
          }
        }
      }
    }
  }

  /*
  製品情報
  -------------------------------------*/
  #PageProducts {
    #Main {
      padding: 23px 0 100px;
      .contBoxTitle {
        h3 {
          font-size: 25px;
          line-height: 1.5;
        }
        p {
          font-size: 12px;
          letter-spacing: 0.1em;
          margin-top: 2px;
        }
      }
      .contBoxCont {
        padding: 14px 20px 35px;
      }
      .contSubBox {
        .contSubBoxTitle {
          font-size: 22px;
          line-height: 33px;
          padding-bottom: 14px;
          margin-bottom: 25px;
        }
        .thumbPostList {
          & > li {
            width: 100%;
            margin-right: 0 !important;
            margin-bottom: 0;
            & + li { margin-top: 45px; }
            a {
              .content {
                padding-top: 12px;
                .selldate { margin: 6px 0 4px; }
                .title { text-align: left; }
                .tag { margin-top: 15px; }
                .desc { margin: 21px 0 14px; }
              }
            }
          }
        }
      }
    }
  }

  #PageProducts.pageIndex {
    #Main {
      .contBox {
        margin-bottom: 23px;
        .contBoxTitle {
          background-size: 40px auto;
          padding: 19px 60px 19px 0;
          &:hover { opacity: 1; }
        }
      }
      .contSubBox {
        & + .contSubBox { margin-top: 49px; }
        .thumbPostList {
          & > li {
            &.lastest {
              // a .thumb { padding-top: 74%; }
            }
          }
        }
      }
      #ContBox01 {
        .contSubBox01 .thumbPostList {
          margin-right: 0;
        }
      }
    }
  }

  /*
  製品情報 カテゴリ
  -------------------------------------*/
  #PageProducts.pageCategory {
    #Main {
      padding-top: 42px;
      .contBox {
        .contSubBox {
          & + .contSubBox { margin-top: 49px; }
          .contSubBoxTitle {
            line-height: 33px;
            padding-bottom: 11px;
            span {
              font-size: 11px;
              letter-spacing: 0.1em;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
  /*
  製品情報 記事詳細
  -------------------------------------*/
  #PageProducts.pageEntry {
    #Main {
      padding-top: 50px;
      .contBox {
        .notice {
          padding: 13px;
          margin-bottom: 41px;
          p {
            font-size: 16px;
            line-height: 18px;
          }
        }
        .boxLink {
          margin-bottom: 55px;
          top: 99px;
          .innerBasic {
            width: 100%;
            padding: 0;
          }
          ul {
            li {
              flex: 1;
              a {
                height: 100%;
                font-size: 12px;
                line-height: 14px;
                padding: 5px 4px 4px;
                span {
                  background-size: 11px auto;
                  padding-left: 15px;
                }
              }
            }
          }
        }
        .contSubBox {
          h3 {
            font-size: 25px;
          }
        }
        .entryHead {
          h3 {
            padding-bottom: 12px;
          }
          h4 {
            line-height: 27px;
            padding: 4px 10px;
            margin: 15px 0 17px;
          }
          .info {
            flex-direction: column;
            align-items: flex-start;
            .title {
              font-size: 22px;
              line-height: 33px;
              margin-right: 0;
            }
            .selldate {
              margin: 3px 0 6px;
            }
          }
        }
        .entryPhoto {
          .slider {
            width: calc(100% + 40px);
            margin-left: -20px;
            height: 100%;
            .slick-track {
              display: flex;
            }
            .slider-item {
              height: auto !important;
            }
          }
          .sliderThumb {
            .slider-item {
              width: calc( (100% - 40px) / 5 ) !important;
              height: 43px;
            }
          }
        }
        .entryContent {
          margin-top: 66px;
          .flexBox01 {
            .spotlight {
              width: 100%;
              padding-right: 0;
              & > * {
                font-size: 25px;
              }
            }
            .content {
              width: 100%;
              padding-left: 0;
              margin-top: 60px;
              p {
                font-size: 14px;
                & + p { margin-top: 24px; }
              }
            }
          }
          .flexBox02 {
            margin-top: 33px;
            .item {
              width: 100%;
              padding-left: 0;
              dl {
                padding: 16px 20px 23px;
              }
            }
          }
        }
        .featureBox,
        .techologBox {
          margin-top: 70px;
          .innerBasic {
            min-width: 100%;
            max-width: 100%;
          }
          .flexBox {
            .item {
              width: 100%;
              margin-bottom: 42px;
              &:nth-of-type(even) { margin-left: 0; }
              figure {
                height: 0;
                padding-top: 73%;
                margin-bottom: 20px;
              }
            }
          }
        }
        .reportBox {
          margin-top: 15px;
          .thumbPostList {
            margin-top: 24px;
            margin-bottom: -30px;
            li {
              width: 100%;
              margin-bottom: 30px;
            }
          }
        }
        .instagramBox {
          .subBox {
            margin-top: 22px;
            padding: 35px 0;
          }
        }
        .specBox {
          margin-top: 69px;
          h3 { margin-bottom: 21px; }
          .subBox {
            & + .subBox { margin-top: 50px; }
          }
          .title {
            font-size: 22px;
            line-height: 33px;
            margin: 11px 0 16px;
          }
          .table {
            .scroll {
              overflow-y: scroll;
              max-height: 350px;
              padding-right: 10px;
            }
            table th {
              position: sticky;
              top: 0;
              &:nth-of-type(1) {
                z-index: 3;
                left: 0;
              }
              &.sticky {
                z-index: 3;
              }
            }
          }
          .content {
            margin: 28px 0 38px;
          }
          .gallery {
            margin-bottom: -20px;
            figure {
              width: calc( (100% - 20px) / 2 );
              margin-right: 20px;
              margin-bottom: 20px;
              &:nth-of-type(even) { margin-right: 0; }
            }
          }
        }
        .orderBox {
          h3 {
            font-size: 25px;
          }
          ul {
            flex-direction: column;
            li {
              width: 100%;
              min-height: 80px;
              margin-right: 0;
              & + li { margin-top: 15px; }
              a {
                background-image: none;
                font-size: 16px;
                line-height: 24px;
                padding: 25px 28px;
                span {
                  background: url(../img/contents/arrow_04.png) no-repeat right center;
                  background-size: 16px auto;
                  padding-right: 29px;
                }
              }
            }
          }
        }
        .relatedBox {
          margin-top: 80px;
          padding: 42px 0 73px;
          .thumbPostList {
            margin-top: 24px;
            margin-bottom: -23px;
            li {
              width: calc( (100% - 20px) / 2 );
              margin-bottom: 23px;
              & + li { margin-top: 0; }
              &:nth-of-type(even) { margin-left: 20px; }
              a {
                .content {
                  padding-top: 2px;
                  .title { font-size: 14px; }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  プロスタッフ
  -------------------------------------*/
  #PageProStaff.pageIndex {
    #Main {
      padding: 44px 0 100px;
      .contBox {
        margin-bottom: 52px;
        h3 {
          padding-bottom: 13px;
        }
        .contSubBox {
          margin-top: 35px;
          .photoBox {
            width: 100%;
            margin-right: 0;
            .sliderThumb {
              .slider-item {
                height: auto;
                img { width: 100%; height: auto; }
              }
            }
          }
          .contentBox {
            width: 100%;
            margin-top: 40px;
            .desc {
              padding: 16px 21px;
            }
            .area {
              margin-top: 35px;
              h4 { margin-bottom: 6px; }
            }
          }
        }
      }
    }
  }

  /*
  ニュース
  -------------------------------------*/
  #PageNews {
    #Main {
      padding: 42px 0 105px;
      .linkArea {
        margin-bottom: 60px;
        overflow: hidden;
        .newsCatList {
          padding: 19px 0 10px 20px;
          li {
            line-height: 24px;
            margin-right: 30px;
            margin-bottom: 10px;
          }
        }
        .newsCatList01 {
          padding-right: 20px;
          margin-top: 15px;
          li {
            width: 100%;
            margin-right: 0;
          }
        }
      }
      .thumbPostList {
        margin-bottom: -40px;
        & > li {
          width: 100%;
          margin-right: 0;
          margin-bottom: 40px;
        }
      }
      .moreBtn {
        margin-top: 45px;
        background-position: right 16px center;
      }
    }
  }

  /*
  販売店情報
  -------------------------------------*/
  #PageStore.pageIndex {
    #Main {
      padding: 45px 0 101px;
      .contBox {
        .contBoxTitle {
          padding-bottom: 12px;
        }
        .pageNav {
          margin-top: 12px;
          ul {
            li {
              margin-right: 30px;
            }
          }
        }
        .contSubBox {
          margin-top: 55px;
          h4 {
            font-size: 22px;
            padding: 7px 0;
            margin-bottom: 25px;
          }
          .subBox {
            margin-bottom: 40px;
            .click {
              font-size: 22px;
              padding: 4px 40px 20px 0;
            }
            .showBox {
              dl {
                margin-top: 25px;
                dt {
                  flex-direction: column;
                  ul { margin: 10px 0 0; }
                }
                dd {
                  margin-top: 7px;
                  ul {
                    margin-top: 5px;
                    li a.tel { pointer-events: auto; }
                  }
                }
              }
            }
          }
        }
        #ContSubBox01 {
          margin-top: 40px;
        }
      }
    }
  }

  /*
  サポート
  -------------------------------------*/
  #PageSupport.pageIndex {
    #Main {
      padding: 42px 0 101px;
      .linkBox {
        overflow: hidden;
        .newsTabList {
          padding-bottom: 10px;
          li { margin-bottom: 2px; }
        }
        .flexBox {
          .item {
            width: 100%;
            height: 210px;
            & + .item { margin-top: 15px; }
            a {
              padding: 22px 0 6px;
              img { width: 120px; }
              p { margin: 5px 0 4px; }
              span { font-size: 12px; }
            }
          }
        }
      }
      .contBox {
        margin-top: 52px;
        .contBoxTitle { font-size: 25px; }
      }
      #ContBox01 {
        .contBoxTitle { margin-bottom: 35px; }
        .contSubBox {
          .thumbPostList {
            margin-bottom: -27px;
            li {
              width: calc( (100% - 15px) / 2 );
              margin-right: 15px;
              margin-bottom: 27px;
              &:nth-of-type(even) { margin-right: 0; }
              a {
                .content {
                  padding-top: 10px;
                  .title {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }
            }
          }
          .moreBtn {
            width: 290px;
            margin-top: 41px;
          }
        }
      }
      #ContBox02 {
        .contBoxTitle { margin-bottom: 14px; }
        .contSubBox {
          .post {
            .subBox {
              .click {
                background-position: right 5px center;
                padding: 25px 56px 25px 0;
              }
              .showBox {
                padding: 20px;
                margin: 2px 0;
                p { line-height: 24px; }
              }
            }
          }
          .button {
            flex-direction: column;
            align-items: center;
            margin-top: 45px;
            li {
              & + li {
                margin-top: 15px;
                margin-left: 0;
              }
              a {
                width: 295px;
                height: 55px;
              }
            }
          }
        }
      }
      #ContBox03 {
        margin-top: 57px;
        .contBoxTitle { margin-bottom: 18px; }
        .contSubBox {
          .telBox {
            padding: 49px 0 37px;
            .tel {
              margin-bottom: 17px;
              p {
                font-size: 22px;
                margin-bottom: 14px;
              }
              h4 { font-size: 36px; }
            }
            & > p {
              line-height: 24px;
              text-align: center;
            }
          }
          .formBox {
            margin-top: 45px;
            p {
              font-size: 18px;
              line-height: 1.5;
            }
            .form {
              margin-top: 13px;
              padding-top: 37px;
              table {
                tr {
                  &:last-of-type th { line-height: 2; }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  サポート 確認画面
  -------------------------------------*/
  #PageSupport.pageConfirm {
    #Main {
      padding: 42px 0 104px;
      .contBox {
        .contSubBox {
          margin-top: 38px;
          .textBox {
            p {
              font-size: 16px;
              line-height: 1.5;
            }
          }
          .formBox {
            margin-top: 48px;
            .form {
              padding-top: 41px;
              table {
                th, td {
                  line-height: 1.5;
                  padding: 0;
                }
                th {
                  margin-bottom: 9px;
                  &:after { top: 2px; }
                }
                td {
                  margin-bottom: 29px;
                  padding-left: 1em;
                }
              }
              .button {
                margin-top: 13px;
                padding-top: 45px;
                position: relative;
                text-align: center;
                button { width: 281px; }
                .return {
                  position: relative;
                  left: auto;
                  bottom: auto;
                  display: inline-block;
                  margin: 16px auto 0;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  サポート 完了画面
  -------------------------------------*/
  #PageSupport.pageThanks {
    #Main {
      padding: 42px 0 88px;
      min-height: auto;
      .contBox {
        .contSubBox {
          margin-top: 38px;
          p {
            font-size: 16px;
            line-height: 1.5;
          }
          .moreBtn {
            margin-top: 48px;
            width: 281px;
          }
        }
      }
    }
  }

  /*
  修理について
  -------------------------------------*/
  #PageRepair.pageIndex {
    #Main {
      padding: 42px 0 102px;
      .linkBox {
        overflow: hidden;
        .newsTabList {
          padding-bottom: 10px;
          li { margin-bottom: 2px; }
        }
      }
      .contBox {
        .contBoxTitle { margin-bottom: 38px; }
        .contSubBox {
          margin-bottom: 35px;
          .contSubBoxTitle {
            padding-bottom: 15px;
            margin-bottom: 45px;
            small {
              display: block;
              margin-top: 2px;
            }
          }
          span {
            text-align: justify;
            &.note {
              padding-left: 0;
              &:before { position: relative; }
            }
            &.red {
              line-height: 24px;
              margin-top: 3px;
            }
          }
          table {
            tr {
              &:last-of-type th { border-bottom-width: 2px; }
            }
            th, td {
              display: block;
              width: 100%;
              line-height: 24px;
            }
            th { padding: 8px 15px; }
            td {
              padding: 15px;
              border-left-width: 0;
              p {
                line-height: 24px;
                letter-spacing: 0.05em;
                & + p { margin-top: 15px; }
              }
              ul {
                & > li {
                  & + li { margin-top: 15px; }
                }
              }
              ol {
                padding: 10px 0 0;
                li {
                  ul { margin-top: 7px 0 10px; }
                }
              }
            }
          }
          ol {
            & > li {
              padding-left: 22px;
              ul li { padding-left: 0.7em; }
            }
          }
          .subBox {
            margin-top: 24px;
            .subBoxTitle { margin-bottom: 15px; }
          }
        }
        .contSubBox01 {
          .contSubBoxTitle { font-size: 18px; }
          .subBox03 {
            margin-top: 45px;
            .subBoxTitle { margin-bottom: 6px; }
            ol {
              li + li { margin-top: 12px; }
            }
          }
        }
        .contSubBox02 {
          .contSubBoxTitle { margin-bottom: 36px; }
          ul {
            & > li {
              padding-left: 20px;
              & + li { margin-top: 15px; }
              ol {
                padding: 0;
                & > li {
                  ul { margin: 0; }
                }
              }
            }
          }
        }
        .contSubBox03 {
          table {
            th, td {
              display: table-cell;
              width: 50%;
            }
            td {
              & + td { border-left-width: 2px; }
            }
          }
          & > p {
            &.right {
              line-height: 24px;
              margin: 9px 0;
            }
          }
        }
        .contSubBox04 {
          margin-top: 48px;
          .subBox {
            margin-top: 14px;
            padding: 29px 25px 30px;
            ul {
              li {
                & + li { margin-top: 15px; }
                .note {
                  line-height: 24px;
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  パーツリスト
  -------------------------------------*/
  #PagePartsList.pageIndex {
    #Main {
      padding: 42px 0 98px;
      .linkBox {
        margin-bottom: 51px;
        overflow: hidden;
      }
      .contBox {
        .contBoxTitle { margin-bottom: 27px; }
        .contSubBox {
          margin-bottom: 11px;
          .contSubBoxTitle {
            min-height: 90px;
            display: flex;
            align-items: center;
            background-size: 40px auto;
            background-position: right center;
            padding: 23.5px 75px 23.5px 0;
          }
          & > .showBox { padding-bottom: 33px; }
          .subBox {
            margin-top: 15px;
            .subBoxTitle {
              background-position: right 12px center;
            }
            .showBox {
              padding: 20px 0 28px;
              .post {
                margin-bottom: 0;
                & + .post { margin-top: 30px; }
                h6 {
                  line-height: 33px;
                  margin-bottom: 4px;
                }
                .scroll {
                  margin-bottom: 20px;
                }
                table {
                  th, td {
                    width: auto;
                    padding-left: 35px;
                    padding-right: 35px;
                    &:first-of-type {
                      width: auto;
                      padding-left: 18px;
                      padding-right: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  カスタマーサービス
  -------------------------------------*/
  #PageCustomer.pageIndex {
    #MainImg {
      height: 198px;
    }
    #Main {
      padding: 50px 0 102px;
      .notice {
        margin-bottom: 49px;
        dl {
          flex-wrap: wrap;
          dt {
            width: 100%;
            padding: 13px 18px;
          }
          dd {
            width: 100%;
            padding: 16px 18px;
          }
        }
      }
      .contBox {
        margin-bottom: 49px;
        .contBoxTitle {
          padding-bottom: 15px;
        }
        .contSubBox {
          .subBox {
            margin-top: 40px;
            &:first-of-type { margin-top: 30px; }
            .click {
              font-size: 22px;
              padding: 5px 40px 22px 0;
            }
            .showBox {
              padding-top: 16px;
              margin-bottom: 0;
              .postList {
                li {
                  line-height: 30px;
                  & + li { margin-top: 14px; }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  リンク
  -------------------------------------*/
  #PageLink.pageIndex {
    #Main {
      padding: 41px 0 92px;
      .contBox {
        margin-bottom: 42px;
        .innerBasic { width: 100%; }
        .contBoxTitle {
          padding-bottom: 12px;
        }
        .contBoxCont {
          margin-top: 38px;
          dl {
            & + dl { margin-top: 24px; }
            dt {
              margin-bottom: 21px;
              h4 {
                font-size: 22px;
                text-align: justify;
              }
              p { margin-top: 0; }
            }
          }
        }
      }
    }
  }

  /*
  企業情報
  -------------------------------------*/
  #PageCompany.pageIndex {
    #MainImg {
      height: 131px;
    }
    #Main {
      padding-bottom: 100px;
      .leadImage {
        padding: 0 15px;
        &:before { height: 80px; }
        figure {
          height: 0;
          padding-top: 66.56716417910448%;
          background-position: left 30% top;
          background-size: 670px auto;
        }
      }
      .pageNav {
        margin: 32px 0 51px;
        ul {
          li {
            & + li { margin-left: 86px; }
          }
        }
      }
      .contBox {
        margin-bottom: 42px;
        .contBoxTitle {
          font-size: 25px;
          padding-bottom: 12px;
        }
        .contSubBox {
          margin-top: 36px;
          dl {
            & + dl { margin-top: 25px; }
            dt {
              font-size: 22px;
              margin-bottom: 8px;
            }
          }
          ol {
            li { padding-left: 32px; }
          }
          table {
            th, td {
              display: block;
              width: 100%;
              padding: 8px 15px;
            }
            td {
              padding: 15px;
              border-left-width: 0;
            }
          }
        }
      }
      #ContBox03,
      #ContBox04 {
        .contSubBox {
          margin-top: 45px;
        }
      }
    }
  }

  /*
  社員あいさつ
  -------------------------------------*/
  #PageMessage.pageIndex {
    #Main {
      padding: 42px 0 93px;
      .pageNav {
        margin-bottom: 44px;
        ul {
          li {
            & + li { margin-left: 86px; }
          }
        }
      }
      .contBox {
        .image {
          img { width: 100%; }
        }
        .contBoxTitle {
          font-size: 25px;
          margin: 35px 0 28px;
        }
        .contSubBox {
          h4, h5 { font-size: 22px; }
          h4 {
            padding-bottom: 14px;
            margin-bottom: 44px;
          }
          .text {
            width: 100%;
            p {
              & + p { margin-top: 22px; }
              &.right {
                margin-top: 26px;
                span {
                  &:last-of-type { margin-top: -3px; }
                }
              }
            }
          }
          div.flexBox {
            margin-bottom: 33px;
            .item01 {
              width: 100%;
              padding-top: 0;
              margin: 0 0 21px;
            }
            .item02 { width: 100%; }
          }
          .career {
            margin-bottom: 40px;
            dl {
              display: block;
              line-height: 24px;
              text-align: justify;
              & + dl { margin-top: 16px; }
              dt {
                width: 100%;
                margin-bottom: 5px;
              }
            }
          }
          .hobby {
            h5 { margin-bottom: 10px; }
          }
        }
      }
    }
  }

  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePolicy.pageIndex {
    #Main {
      padding: 41px 0 93px;
      .contBox {
        .innerBasic { width: 100%; }
        .contSubBox {
          margin-top: 43px;
          &:last-of-type {
            p:last-of-type { letter-spacing: 0.05em; }
          }
          h4 {
            font-size: 22px;
            padding-bottom: 16px;
            margin-bottom: 35px;
          }
          p {
            strong { display: block; }
          }
        }
      }
    }
  }

  ::-webkit-full-page-media, _:future, body {}
  ::-webkit-full-page-media, _:future, #Page.pageIndex #Main .importantBox dl dt { padding-bottom: 3px; }

}

@media print,
screen and (max-width: 375px) {
  #Page.pageIndex {
    #MainImg #MainImgInner {
      #Slick .item a {
        background-size: 375px auto;
      }
    }
  }
}

  
